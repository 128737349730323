<template>
    <li class="nav_item" @click="$emit('click')">
        <template v-if="href">
            <router-link :to="nav_link" class="nav_link" v-bind:style="{ fontSize: font_size, color: color }">{{
                nav_text
            }}</router-link>
        </template>
        <template v-else>
            <a :href="nav_link" class="nav_link" v-bind:style="{ fontSize: font_size, color: color }">{{ nav_text }}</a>
        </template>
    </li>
</template>

<script>
export default {
    props: ['nav_link', 'nav_text', 'font_size', 'color', 'href'],
}
</script>

<style lang="scss" scoped>
.nav_item {
    list-style: none;
    padding: 0;
    margin: 0;

    .nav_link {
        color: $primary;
        text-decoration: none;
        padding: 0;
        margin: 0;
        font-size: 16px;
        transition: 500ms;
        // letter-spacing: 0.09em;

        &:hover {
            color: #01493b;
        }
    }
}
</style>
